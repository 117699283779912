import axios from "axios";
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class ParcelOrderService {
  index() {
    return axios.get(API_URL + "parcel_orders", { headers: authHeader() }).then(response => {
        return response.data;
    });
  }

  create(user_id, parcel_id, price_id, pickup_location, quantity, collection_date, departure_province, destination_province, departure_town, destination_town) {
    return axios
      .post(API_URL + "parcel_orders", { parcel_order: { user_id, parcel_id, price_id, pickup_location, quantity, collection_date, departure_province, destination_province, departure_town, destination_town }
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }

  findOrder(parcel_reference){
    return axios.get(API_URL + "parcel_orders/" + parcel_reference, {headers: authHeader()})
    .then(response => {
      return response.data;
    });
  }

  updateOrderStatus(parcel_order_id, status) {
    return axios
      .patch(API_URL + "parcel_orders/" + parcel_order_id, { parcel_order: { status: status }
      }, {headers: authHeader()})
      .then(response => {
        return response.data;
      });
  }

  parcel_order_fulfillments(){
    return axios.get(API_URL + "parcel_order_fulfilments", { headers: authHeader() }).then(response => {
      return response.data;
  });
  }
}

export default new ParcelOrderService();