const fixedInputClass="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"

export default function Input({
    handleChange,
    value,
    id,
    name,
    type,
    isRequired=false,
    placeholder,
    customClass
}){
    return(
      <input
        onChange={handleChange}
        value={value}
        id={id}
        name={name}
        type={type}
        required={isRequired}
        className={fixedInputClass+customClass}
        placeholder={placeholder} />
    )
}
