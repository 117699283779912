import banner from "../../assets/img/asset7.png";

export const AboutPage = () => {

	return (
		<div className="w-full min-h- flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200 ">
			<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 py-10 b">
				<div className="flex justify-center flex-col gap-4 p-5 md:p-10	">
                    <div class="flex flex-wrap items-center rounded-md">
                        <div class="text-center border-dashed border-2 border-slate-200 dark:border-slate-100 rounded-md w-full p-20">
                            <i class='bx bxs-contact bx-lg mb-5 dark:text-white'></i>
                            <p class="text-xl mb-2 uppercase font-bold dark:text-gray">Who are we, you ask.</p>
                            <spam class="text-m text-slate-400 block mb-10 dark:text-slate-500">Yet Another <a href="https://liftsearch.co.za" className="text-purple-500" target="_blank">Liftsearch</a> innovation. Parcelin is born out of the demand for reliable and cheaper same day parcel delivery in South Africa. Starting in Limpopo(Giyani, Malamulele and Tzaneen) and All of Gauteng, it is the fastest growing long distance parcel delivery app.</spam>
                            <a href="/" class="bg-purple-600 rounded-full px-5 py-3 text-white hover:bg-purple-500 w-52">Start Parcelin</a>
                        </div>
                    </div>
				</div>
				<div className="flex justify-center flex-col ">
					<div className="text-center py-6 px-6 lg:px-8 lg:py-8">
						<div className="text-purple-800 text-3xl md:text-4xl font-semibold "> Revolutionizing Long Distance Parcel Delivery, One Parcel At A Time.</div>
					</div>
					<div className="flex items-center justify-center">
						<img className="h-96" src={banner} alt="Parcelin" />
					</div>
				</div>
			</div>
		</div>
	);
};
