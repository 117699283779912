import axios from "axios";
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  login(cellphone, password) {
    return axios
      .post(API_URL + "auth/login", {
        cellphone,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  verifyEmail(confirmation_token){
    return axios.post(API_URL + "auth/confirm_email/" + confirmation_token, {});
  }

  verifyNumber(verification_number){
    return axios.post(API_URL + "auth/confirm_cellphone/" + verification_number, {});
  }

  ResetPassword(password, reset_token){
    return axios.post(API_URL + "auth/reset_password/" + reset_token, {
      password
    });
  }

  ResetRequest(email, source='parcelin'){
    return axios.post(API_URL + "auth/reset_password", {
      email,
      source
    });
  }

  register(cellphone, first_name, last_name, role, gender, email, password, referral_key, source=1) {
    return axios.post(API_URL + "users", {
      cellphone,
      first_name,
      last_name,
      role,
      email,
      gender,
      password,
      referral_key,
      source
    }).then(response => {
      return response.data;
    }).catch(err => {
      return err
    });;
  }

  update(cellphone, first_name, last_name, email, password) {
    return axios.patch(API_URL + "users", {
      cellphone,
      first_name,
      last_name,
      email,
      password,
      headers: authHeader,
    }).then(response => {
      return response.data;
    }).catch(err => {
      return err
    });;
  }


  isLogin(){
    return JSON.parse(localStorage.getItem('user')) != null
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();