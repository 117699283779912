import { BsDot } from "react-icons/bs";

import { Accordion, AccordionTab } from "primereact/accordion";

import { HiCheck, HiX } from "react-icons/hi";
export default function OrderCardTable({ order }) {
	const orderStatuses = { pending_collection: 0, collected: 1, enroute: 2, delivered: 3 };
    const options = { 
        weekday: 'short', 
        day: 'numeric', 
        month: 'long' 
    };

	return (
		<Accordion>
			<AccordionTab
				className="w-full bg-white rounded-2xl shadow p-2"
				header={
					<div className="flex flex-col lg:flex-row  justify-evenly">
						<div className="w-full h-12 bg-white rounded-lg grid grid-cols-5 flex-row justify-evenly p-2">
							<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
								<p className="font-light"> {new Date(order.parcel_order.collection_date).toLocaleDateString('en-US', options)} </p>
							</div>
							<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
								<p className="text-lg font-semibold text-center">{order.parcel_order.destination_province.name}</p>
							</div>
							<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
								<p>{order.parcel_order.quantity}</p>
							</div>
							<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
								<p>{order.parcel_order.price.price_type.name}</p>
							</div>
							<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
								<div className="flex flex-row gap-2 justify-center items-center	 rounded-lg bg-slate-100 px-2" >
                                	<p className={ orderStatuses[order.status] >= 1 ? 'text-lg font-semibold text-green-500' : 'text-lg font-semibold text-gray-500' } >Collected</p>
									<HiCheck size={18} color={ orderStatuses[order.status] >= 1 ? 'green' : 'gray' } />
								</div>
								<div className="flex flex-row gap-2 justify-center items-center	 rounded-lg bg-slate-100 px-2">
                                <p className={ orderStatuses[order.status] >= 3 ? 'text-lg font-semibold text-green-500' : 'text-lg font-semibold text-gray-500' }>Delivered</p>
									<HiX size={18} color={ orderStatuses[order.status] >= 2 ? 'green' : 'gray' }  />
								</div>
							</div>
						</div>
					</div>
				}
			>

				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
					<div className="flex  flex-col gap-4">
						<div className="flex flex-col gap-2">
							<p className="font-semibold text-lg">Delivery Type</p>
							<div className="flex gap-1">
								<p className="font-light"> {order.parcel_order.price.price_type.name} </p>
								<BsDot size={30} />
								<p className="font-light"> Collection</p>
							</div>
						</div>
						<div className="flex flex-col gap-2">
							<p className="font-semibold text-lg">Collection Date</p>
							<div className="flex">
								<p className="font-light"> {order.parcel_order.collection_date}</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<p className="font-semibold text-lg">Collection Address</p>
							<div className="flex">
								<p className="font-light">{order.parcel_order.pickup_location} </p>
							</div>
						</div>
						<div className="flex  flex-col gap-1">
							<p className="font-semibold text-lg">Delivery Address</p>
							<div className="flex">
								<p className="font-light">{order.parcel_order.destination_town.name}</p>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-2">
							<p className="font-semibold text-lg">Payment</p>
							<div className="flex">
								<p className="font-light">
								R{order.parcel_order.price.value * order.parcel_order.quantity}
									<a href="/pay_now" style={{ color: order.parcel_order.status === "created" && order.parcel_order.status !== "cancelled" ? "red" : "purple" }}>
										{" "}
										{order.parcel_order.status === "created" && order.parcel_order.status !== "cancelled" ? "Pending Payment" : order.parcel_order.status.toUpperCase()}
									</a>
								</p>
							</div>
						</div>
						<div className="flex  flex-col gap-1">
							<p className="font-semibold text-lg">Tracking Number</p>
							<div className="flex">
								<p className="font-light"> {order.parcel_order.reference_number} </p>
							</div>
						</div>
					</div>
				</div>
			</AccordionTab>
		</Accordion>
	);
}
