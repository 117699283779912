import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class TownService {
  index() {
    return axios.get(API_URL + "towns", {}).then(response => {
        return response.data;
    });
  }

  by_province(id) {
    return axios.get(API_URL + "towns", {}).then(response => {
      return response.data.filter((town) => town.province_id.toString() === id.toString() );
  });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new TownService();