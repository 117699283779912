import { BsSearch } from "react-icons/bs";
import banner from "../../assets/img/asset7.png";
import React, { useState } from "react";
import ParcelOrderService from "../../services/parcel-order.service";
import { useLocation } from "react-router-dom";
import Input from "../../components/Input";
import { EmptyState } from "../../components/EmptyState";
import OrderCard from "../../components/OrderCard";

export const TrackParcelPage = () => {
	const [orderState, setOrderState] = useState({
		order: {},
		reference_number: "",
		show_results: false,
		hasError: false
	});

	const { order, reference_number, show_results, hasError } = orderState;

	const handleChange = (e) => {
		setOrderState({ ...orderState, [e.target.id]: e.target.value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if(reference_number === ""){
			setOrderState((prevState) => ({
				...prevState,
				show_results: false,
				hasError: true
			}));

			return false
		}else{
			try {
				ParcelOrderService.findOrder(reference_number).then((data) => {
					setOrderState((prevState) => ({
						...prevState,
						order: data,
						show_results: true,
						hasError: false
					}));
				})
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		}
	}


	return (
		<div className="w-full min-h- flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200 ">
			<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 py-10 b">
				<div className="flex justify-center flex-col gap-4 p-5 md:p-10	">
					<form className="w-full py-4  flex flex-row  justify-center items-center gap-4" onSubmit={handleSubmit}>
						<Input
							handleChange={handleChange}
							value={reference_number}
							labelText="Reference Number"
							labelFor="Reference Number"
							id="reference_number"
							name="reference_number"
							type="text"
							isRequired={true}
							placeholder="Reference Number"
							customClass="w-full bg-white border border-gray-300 hover:border-gray-400 px-3 py-2 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
						/>
						<div class="relative inline-block text-center">
							<button
								className="group relative w-full flex gap-2 items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
								onClick={handleSubmit}
							>
								Search
								<BsSearch size={15} />
							</button>
						</div>
					</form>



					{(show_results && order) && (
						<div className="w-full py-4 flex flex-col gap-4">
							<OrderCard
							order={order} />
						</div>
					)}
					{(!order) && (<EmptyState />)}
					{hasError && <EmptyState />}
				</div>
				<div className="flex justify-center flex-col ">
					<div className="text-center py-6 px-6 lg:px-8 lg:py-8">
						<div className="text-purple-800 text-3xl md:text-4xl font-semibold "> Track Your Parcel, Right.</div>
					</div>
					<div className="flex items-center justify-center">
						<img className="h-96" src={banner} alt="Parcelin" />
					</div>
				</div>
			</div>
		</div>
	);
};
