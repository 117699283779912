import { useState, useEffect } from "react";
import { signupFields } from "../constants/formFields";
import FormAction from "./FormAction";
import AuthService from "../services/auth.service";
import Input from "./Input";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const fields = signupFields;
let fieldsState = { hasError: false, errorMessage: '', isLoading: false };

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {
	const [signupState, setSignupState] = useState(fieldsState);
	const location = useLocation();
	const navigate = useNavigate();

	let { hasError, errorMessage, isLoading } = signupState

	const handleChange = (e) => setSignupState({ ...signupState, [e.target.id]: e.target.value });

	const handleSubmit = (e) => {
		e.preventDefault();
		setSignupState({ ...signupState,
			hasError: false
		});
		createAccount();
	};

	useEffect(() => {

        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            if (jwtDecode(currentUser["token"]).exp > Date.now() / 1000) {
                navigate('/parcels', {})
            }
        }
    }, [])

	//Handle Login API Integration here
	const authenticateUser = (email, password) => {
		AuthService.login(email, password).then(
			() => {
				if(location?.state?.redirect_to !== undefined)
                {
                    navigate(location.redirect_to, { state: location.state, redirect_to: location?.state?.redirect_to });
                }else{
                    window.location.replace("/parcels");
                }},
			(error) => {
				console.log(error);
			}
		);
	};

	//Handle Login API Integration here
	const createAccount = () => {
		setIsLoading(true);
		let signupFields = {
			email: signupState["email"],
			cellphone: signupState["cellphone"],
			first_name: signupState["first_name"],
			last_name: signupState["last_name"],
			password: signupState["password"],
			referral_key: null,
			gender: null,
			role: 'rider'
		};

		AuthService.register(signupFields.cellphone, signupFields.first_name, signupFields.last_name, signupFields.role, signupFields.gender, signupFields.email, signupFields.password, signupFields.referral_key).then(
			(data) => {
				setIsLoading(false);
				if(data.status === 200){
					authenticateUser(signupFields.email, signupFields.password)
				}else{
					setSignupState({ ...signupState,
						hasError: true,
						errorMessage: data?.response?.data?.message
					});	
				}
			},
			(error) => {
				setIsLoading(true);
                setSignupState({ ...signupState,
                    hasError: true,
                    errorMessage: error.response.data.message
                });

				console.log(error.response.data.message);
			}
		);
	};

	const setIsLoading = (value) => {
        setSignupState({ ...signupState,
            isLoading: value
        });
    }

	return (
		<form className="py-10 px-10  flex flex-col gap-4 w-full md:w-[60%] lg:w-[35%] bg-white  rounded-lg" onSubmit={handleSubmit}>
			{hasError && <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
				{errorMessage}. You can use your <a href="https://liftsearch.co.za" target="_blank" className="text-purple-500">Liftsearch</a> credentials to <a href="login" className="text-purple-500">sign in</a>.
			</div>}
			<div className=" text-center text-black text-2xl font-bold ">Create Account</div>
				{/* <div className="-space-y-px"> */}
					{
						fields.map(field=>
							<div className="flex flex-col  md:flex-row">
								<Input
									required
									key={field.id}
									handleChange={handleChange}
									value={setSignupState[field.id]}
									labelText={field.labelText}
									labelFor={field.labelFor}
									id={field.id}
									name={field.name}
									type={field.type}
									isRequired={field.isRequired}
									placeholder={field.placeholder}
							/>
							</div>
						
						)
					}
				{/* </div> */}

			{/* <div className="flex flex-col  md:flex-row gap-4 ">
						<Input
                                key={field.id}
                                handleChange={handleChange}
                                value={loginState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id="first_name"
                                name="first_name"
                                type="text"
                                isRequired="true"
                                placeholder={field.placeholder}
                        />
				<input
					type="text"
					placeholder="Last Name"
					className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
				/>
			</div>

			<div className="flex flex-col  md:flex-row gap-4 ">
				<input
					type="email"
					placeholder="Email"
					className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
				/>
				<input
					type="text"
					placeholder="Phone"
					className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
				/>
			</div>

			<div className="flex flex-col  md:flex-row gap-4 ">
				<input
					type="password"
					placeholder="Password"
					className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
				/>
				<input
					type="password"
					placeholder="Confirm Password"
					className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
				/>
			</div> */}

			<FormAction handleSubmit={handleSubmit} loading={isLoading} text="Create Account" />

			<div className="text-center flex gap-2">
				<div className=" text-black text-base font-medium  ">Already registered on Liftsearch? <a href="login" className="text-right text-purple-600 text-base font-medium">Log In</a></div>
			</div>
		</form>
	);
}
