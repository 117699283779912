import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignupPage } from "./pages/auth/signup";
import { LoginPage } from "./pages/auth/login";
import { ParcelPage } from "./pages/content/parcel";
import { ParcelsPage } from "./pages/content/parcels";
import { CartPage } from "./pages/content/cart";
import { HomePage } from "./pages/content/home";
import { TrackParcelPage } from "./pages/content/track_parcel";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import PrivateRoute from "./authorization/private-route.component";
import { jwtDecode } from "jwt-decode";
import AuthService from "./services/auth.service";
import { ProfilePage } from "./pages/content/profile";
import { ResetRequestPage } from "./pages/auth/request-reset-password";
import { ResetPasswordPage } from "./pages/auth/reset-password";
import { AboutPage } from "./pages/content/about";
import { DriverDashBoard } from "./pages/content/driver_dashboard";

function App() {
	const currentUser = AuthService.getCurrentUser();
	if (currentUser) {
		if (jwtDecode(currentUser["token"]).exp < Date.now() / 1000) {
			AuthService.logout();
		}
	}

	return (
		<div className="min-h-screen ">
			<Header />
			<div className="flex items-center justify-center ">
				<div className="flex flex-col items-center w-full">
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<HomePage />} />
							<Route path="/login" element={<LoginPage />} />
							<Route path="/reset-password" element={<ResetRequestPage />} />
							<Route path="/auth/reset-password/:reset_token" element={<ResetPasswordPage />} />
							<Route path="/signup" element={<SignupPage />} />
							<Route path="/home" element={<HomePage />} />
							<Route path="/about-us" element={<AboutPage />} />
							<Route path="/track_parcel" element={<TrackParcelPage />} />
							<Route exact path="/cart" element={<CartPage />} />
							<Route exact path="/profile" element={<PrivateRoute />}>
								<Route path="/profile" element={<ProfilePage />} />
							</Route>
							<Route exact path="/dashboard" element={<PrivateRoute />}>
								<Route path="/dashboard" element={<DriverDashBoard />} />
							</Route>
							<Route exact path="/parcels" element={<PrivateRoute />}>
								<Route exact path="/parcels" element={<ParcelsPage />} />
							</Route>
							<Route exact path="/parcel/:parcel_id" element={<PrivateRoute />}>
								<Route exact path="/parcel/:parcel_id" element={<ParcelPage />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default App;
