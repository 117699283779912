import { BsArrowLeftRight, BsDot } from "react-icons/bs";
import { CiDeliveryTruck, CiGift, CiCircleCheck } from "react-icons/ci";
import { Accordion, AccordionTab } from "primereact/accordion";

export default function OrderCard({order}){
    const orderStatuses = { 'pending_collection': 0, 'collected': 1, 'enroute': 2, 'delivered': 3 }

    const options = { 
        weekday: 'short', 
        day: 'numeric', 
        month: 'long' 
    };

    return(<Accordion>
        <AccordionTab
            className="w-full bg-white rounded-2xl shadow p-2"
            header={
                <div className="flex flex-col lg:flex-row  justify-evenly">
                    {/* <div className="w-full p-2">
                        <div className="grid grid-cols-3">
                            <p className="text-lg justify-center text-center items-center font-semibold">{order.departure_province.name}</p>
                            <div className="flex">
                                <BsArrowLeftRight size={20} />
                            </div>
                            <p className="text-lg font-semibold">{order.destination_province.name}</p>
                        </div>
                    </div> */}
                    <div className="w-full p-2">
                        <div className="grid grid-cols-3 justify-center items-center">
                            <p className="text-lg font-semibold text-center">{order.departure_province.name}</p>
                            <div className="flex justify-center">
                                <BsArrowLeftRight size={20} />
                            </div>
                            <p className="text-lg font-semibold text-center">{order.destination_province.name}</p>
                        </div>
                    </div>
                    <div className="w-full p-2">
                        <div className="grid grid-cols-3">
                            <div className="flex gap-1">
                                <CiGift size={25} color={ orderStatuses[order.parcel_order_fulfilment.status] >= 1 ? 'green' : 'gray' } />
                                <p className={ orderStatuses[order.parcel_order_fulfilment.status] >= 1 ? 'text-lg font-semibold text-green-500' : 'text-lg font-semibold text-gray-500' } >Collected</p>
                            </div>

                            <div className="flex gap-1">
                                <CiDeliveryTruck size={25} color={ orderStatuses[order.parcel_order_fulfilment.status] >= 2 ? 'green' : 'gray' } />
                                <p className={ orderStatuses[order.parcel_order_fulfilment.status] >= 2 ? 'text-lg font-semibold text-green-500' : 'text-lg font-semibold text-gray-500' }>Enroute </p>
                            </div>

                            <div className="flex gap-1">
                                <CiCircleCheck size={25} color={ orderStatuses[order.parcel_order_fulfilment.status] >= 3 ? 'green' : 'gray' } />
                                <p className={ orderStatuses[order.parcel_order_fulfilment.status] >= 3 ? 'text-lg font-semibold text-green-500' : 'text-lg font-semibold text-gray-500' }>Delivered</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        >
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="flex  flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-lg">Delivery Type</p>
                        <div className="flex gap-1">
                            <p className="font-light"> {order.price.price_type.name} </p>
                            <BsDot size={30} />
                            <p className="font-light"> Collection</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-lg">Collection Date</p>
                        <div className="flex">
                            <p className="font-light"> {new Date(order.collection_date).toLocaleDateString('en-US', options)}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-lg">Collection Address</p>
                        <div className="flex">
                            <p className="font-light">{order.pickup_location} </p>
                        </div>
                    </div>
                    <div className="flex  flex-col gap-1">
                        <p className="font-semibold text-lg">Delivery Address</p>
                        <div className="flex">
                            <p className="font-light">{order.destination_town.name}</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <p className="font-semibold text-lg">Payment</p>
                        <div className="flex">
                            <p className="font-light">R{order.price.value * order.quantity}<a href={`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=11799876&item_name=Parcelin%20Order+${order?.reference_number}&item_description=Parcelin%20Order+R${order?.price?.value*order.quantity}+deposit+per+seat&amount=${order?.price?.value*order.quantity}&return_url=https://parcelin.co.za/parcels&cancel_url=https://parcelin.co.za/cancel_payment?parcel_order_id=${order?.id}`} style={{color: order.status === 'created' && order.status !== 'cancelled' ? "red" : "purple"}}> { order.status === 'created' && order.status !== 'cancelled' ? "Pay Now" : order.status.toUpperCase() }</a></p>
                        </div>
                    </div>
                    <div className="flex  flex-col gap-1">
                        <p className="font-semibold text-lg">Tracking Number</p>
                        <div className="flex">
                            <p className="font-light"> {order.reference_number} </p>
                        </div>
                    </div>
                </div>
            </div>
        </AccordionTab>
    </Accordion>)
}