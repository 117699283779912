import Header from "../../components/Header";
import Signup from "../../components/Signup";

export const SignupPage = () => {
	return (
		<div className="flex w-full justify-center items-center p-2 bg-gradient-to-br from-cyan-100 to-fuchsia-200">
			<Signup />
		</div>
	);
};
