const loginFields=[
    {
        labelText:"Email address",
        labelFor:"email-address",
        id:"email-address",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address"   
    },
    {
        labelText:"Password",
        labelFor:"password",
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Password"   
    }
]

const signupFields=[
    {
        id:"cellphone",
        name:"cellphone",
        type:"text",
        autoComplete:"cellphone",
        isRequired:true,
        placeholder:"Cellphone"   
    },
    {
        id:"email",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address"   
    },
    {
        id:"first_name",
        name:"first_name",
        type:"text",
        autoComplete:"first-name",
        isRequired:true,
        placeholder:"First Name"   
    },
    {
        id:"last_name",
        name:"last_name",
        type:"text",
        autoComplete:"last-name",
        isRequired: true,
        placeholder:"Last Name"   
    },
    {
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"current-password",
        isRequired:true,
        placeholder:"Password"   
    },
    {
        id:"confirm-password",
        name:"confirm-password",
        type:"password",
        autoComplete:"confirm-password",
        isRequired:true,
        placeholder:"Confirm Password"   
    }
]

const passwordResetFields=[
    {
        id:"email",
        name:"email",
        type:"email",
        autoComplete:"email",
        isRequired:true,
        placeholder:"Email address"   
    }
]

const resetFields=[
    {
        id:"password",
        name:"password",
        type:"password",
        autoComplete:"password",
        isRequired:true,
        placeholder:"New Password"   
    }
]

export {loginFields,signupFields, passwordResetFields, resetFields}