import { useState, useEffect } from "react";
import { loginFields } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import AuthService from "../services/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const fields = loginFields;
let fieldsState = { hasError: false, errorMessage: '', isLoading: false};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    
	const [loginState, setLoginState] = useState(fieldsState);

	const handleChange = (e) => {
		setLoginState({ ...loginState, [e.target.id]: e.target.value });
	};

    let { hasError, errorMessage, isLoading } = loginState

    useEffect(() => {

        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            if (jwtDecode(currentUser["token"]).exp > Date.now() / 1000) {
                navigate('/parcels', {})
            }
        }
    }, [])

	const handleSubmit = (e) => {
        e.preventDefault();
        setLoginState({ ...loginState,
            hasError: false,
        });
		authenticateUser();
	};

	//Handle Login API Integration here
	const authenticateUser = () => {
        setIsLoading(true);

		let loginFields = {
			email: loginState["email-address"],
			password: loginState["password"],
		};

		AuthService.login(loginFields.email, loginFields.password).then(
			() => {
                setIsLoading(false);

                if(location?.state?.redirect_to !== undefined)
                {
                    navigate(location.state?.redirect_to, { state: location?.state });
                }else{
                    window.location.replace("/parcels");
                }
			},
			(error) => {
                setIsLoading(false);
                setLoginState({ ...loginState,
                    hasError: true,
                    errorMessage: error?.response?.data?.message
                });

				console.log(error?.response?.data?.message);
			});
	};

    const redirectWithState = () => {
        if(location?.state?.redirect_to !== undefined)
        {
            navigate("/signup", { state: location?.state, redirect_to: location?.state?.redirect_to });
        }else{
            window.location.replace("/signup");
        }
    }

    const setIsLoading = (value) => {
        setLoginState({ ...loginState,
            isLoading: value
        });
    }

	return (
		<form className="py-10 px-10  flex flex-col gap-4 w-full md:w-[60%] lg:w-[35%] bg-white  rounded-lg" onSubmit={handleSubmit}>
                {hasError && <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
				    {errorMessage}. You can use your <a href="https://liftsearch.co.za" target="_blank" className="text-purple-500">Liftsearch</a> credentials to <a href="/login" className="text-purple-500">sign in</a>.
                </div>}
                {
                    fields.map(field=>
                            <Input
                                required
                                key={field.id}
                                handleChange={handleChange}
                                value={loginState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={field.placeholder}
                        />
                    
                    )
                }
			<FormExtra />
			<FormAction handleSubmit={handleSubmit} loading={isLoading} text="Login" />
    
			<div className="text-center flex gap-2">
				<div className=" text-black text-base font-medium">Don’t have an Account?</div>
				<div className="text-right text-purple-600 text-base font-medium" onClick={redirectWithState}>Sign Up</div>
			</div>
		</form>
	);
}
