import { useState, useEffect } from "react";
import { resetFields } from "../constants/formFields";
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import AuthService from "../services/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const fields = resetFields;
let fieldsState = { hasError: false, errorMessage: '', isLoading: false};
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ResetPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    
	const [resetState, setResetState] = useState(fieldsState);

	const handleChange = (e) => {
		setResetState({ ...resetState, [e.target.id]: e.target.value });
	};

    let { hasError, errorMessage, isLoading } = resetState

    useEffect(() => {
        // alert(window.location.toString().split("reset-password/")[1])
        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            if (jwtDecode(currentUser["token"]).exp > Date.now() / 1000) {
                navigate('/parcels', {})
            }
        }
    }, [])

	const handleSubmit = (e) => {
        e.preventDefault();
        setResetState({ ...resetState,
            hasError: false,
        });
		authenticateUser();
	};

	//Handle Login API Integration here
	const authenticateUser = () => {
        setIsLoading(true);

		let resetFields = {
			password: resetState["password"],
		};

		AuthService.ResetPassword(resetFields.password, window.location.toString().split("reset-password/")[1]).then(
			() => {
                setIsLoading(false);
                window.location.replace("/login");
			},
			(error) => {
                setIsLoading(false);
                setResetState({ ...resetState,
                    hasError: true,
                    errorMessage: error?.response?.data?.message
                });

				console.log(error?.response?.data?.message);
			});
	};

    const setIsLoading = (value) => {
        setResetState({ ...resetState,
            isLoading: value
        });
    }

	return (
		<form className="py-10 px-10  flex flex-col gap-4 w-full md:w-[60%] lg:w-[35%] bg-white  rounded-lg" onSubmit={handleSubmit}>
                {hasError && <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
				    {errorMessage}. You can use your <a href="https://liftsearch.co.za" target="_blank" className="text-purple-500">Liftsearch</a> credentials to <a href="/login" className="text-purple-500">sign in</a>.
                </div>}
                {
                    fields.map(field=>
                            <Input
                                required
                                key={field.id}
                                handleChange={handleChange}
                                value={resetState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={field.placeholder}
                        />
                    
                    )
                }
			<FormAction handleSubmit={handleSubmit} loading={isLoading} text="Login" />
		</form>
	);
}
