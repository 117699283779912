import React, { useEffect, useState } from "react";
import ParcelOrderService from "../../services/parcel-order.service";
import { EmptyState } from "../../components/EmptyState";
import OrderCard from "../../components/OrderCard";
import OrderCardTable from "../../components/OrderCardTable";
import { FaSort } from "react-icons/fa6";

export const DriverDashBoard = () => {
	const [OrdersState, setOrderState] = useState({
		orders: [],
	});

	const { orders } = OrdersState;

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const data = await ParcelOrderService.parcel_order_fulfillments();
				setOrderState((prevState) => ({
					...prevState,
					orders: data,
				}));
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		};

		fetchOrders();
	}, []);

	const data = [
		{ id: 1, name: "John Doe", age: 30, email: "john@example.com" },
		{ id: 2, name: "Jane Smith", age: 25, email: "jane@example.com" },
		// Add more data objects as needed
	];

	return (
		<div className="w-full min-h- flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200 ">
			<div className="p-5 lg:px-20 lg:py-20 flex flex-col gap-4">
				<div className=" text-black text-lg md:text-3xl font-extrabold ">Pending Deliveries</div>
				<div className="flex flex-col gap-2">
					<div className="text-black text-lg font-light ">{Date.UTC}</div>
				</div>

				<div className="w-full h-12 bg-violet-200 rounded-lg grid grid-cols-5 flex-row justify-evenly p-2 pl-7">
					<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
						<p>Date</p>
						<FaSort />
					</div>
					<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
						<p>Destination</p>
						<FaSort />
					</div>
					<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
						<p>Quantity</p>
						<FaSort />
					</div>
					<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
						<p>Delivery Type</p>
						<FaSort />
					</div>
					<div className="text-black text-lg font-light flex flex-row gap-2 justify-center items-center cursor-pointer">
						<p>Status</p>
						<FaSort />
					</div>
				</div>

				{orders && orders?.upcoming_parcels?.length > 0 ? orders?.upcoming_parcels?.map((order) => <OrderCardTable order={order} />) : <EmptyState />}

				<div className=" text-black text-lg md:text-3xl font-extrabold ">Past Deliveries</div>

				<div className="flex flex-col gap-2">{/* <div className="text-black text-lg font-light ">29 Dec 2022</div> */}</div>

				{orders && orders?.past_parcels?.length > 0 ? orders?.past_parcels?.map((order) => <OrderCardTable order={order} />) : <EmptyState />}
			</div>
		</div>
	);
};
