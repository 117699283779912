import { RotatingLines } from 'react-loader-spinner'

export default function FormAction({ handleSubmit, type = "Button", action = "submit", text, icon, loading = false }) {
	return (
		<div className="flex">
			{type === "Button" ? (
				<button
					type={action}
					className="group relative w-full flex gap-2 items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
					onSubmit={handleSubmit}
				>
					{text}
					{icon ? icon : ""}
					<RotatingLines
						strokeColor="white"
						strokeWidth="5"
						animationDuration="0.75"
						width="20"
						visible={loading}
					/>
				</button>
			) : (
				<></>
			)}
		</div>
	);
}
