import React, { useEffect, useState } from "react";
import FormAction from "../../components/FormAction";
import banner from "../../assets/img/asset7.png";
import { SlBasketLoaded, SlDiamond, SlHeart, SlHome, SlNotebook, SlPeople, SlPhone, SlUmbrella, SlUser, SlWallet } from "react-icons/sl";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FiBox, FiUser } from "react-icons/fi";
import { CiMonitor } from "react-icons/ci";
import { BsStarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ProvinceService from "../../services/province.service";
import TownService from "../../services/town.service";
import ParcelService from "../../services/parcel.service";

export const HomePage = () => {
	const [createOrderState, setCreateOrderState] = useState({
		departure_province: "",
		destination_province: "",
		departure_town: "",
		destination_town: "",
		parcel_type: "",
		provinces: [],
		destination_provinces: [],
		departure_towns: [],
		destination_towns: [],
		parcels: [],
		start_prices: ["60", "100"]
	});

	const permittedTowns = ['Giyani', 'Malamulele', 'Tzaneen']

	const { departure_province, destination_province, departure_town, destination_town, parcel_type, provinces, destination_provinces, departure_towns, destination_towns, parcels, start_prices } = createOrderState;

	const navigate = useNavigate();

	const handleSelectChange = (event) => {
		const { id, value } = event.target;

		if(id==='departure_province'){
			setCreateOrderState((prevState) => ({
				...prevState,
				['departure_town']: '',
				['destination_province']: '',
				['destination_town']: '',
				['destination_towns']: [],
			}));
		}

		setCreateOrderState((prevState) => ({
			...prevState,
			[id]: value,
		}));
		// You can perform additional actions when the selection changes
	};

	const handleRadioButtonChange = (event) => {
		const { name, value } = event.target;
		setCreateOrderState((prevState) => ({
			...prevState,
			[name]: value,
		}));
		// You can perform additional actions when the input changes
	};

	useEffect(() => {
		const fetchProvinces = async () => {
			try {
				const data = await ProvinceService.index();
				setCreateOrderState((prevState) => ({
					...prevState,
					provinces: data,
				}));
			} catch (error) {
				console.error("Error fetching provinces:", error);
			}
		};

		const fetchParcels = async () => {
			try {
				const data = await ParcelService.index();
				setCreateOrderState((prevState) => ({
					...prevState,
					parcels: data,
				}));
			} catch (error) {
				console.error("Error fetching parcel types:", error);
			}
		};

		const fetchDestinationProvinces = async () => {
			try {
				if (departure_province) {
					const data = await ProvinceService.excluding_province(departure_province);
					setCreateOrderState((prevState) => ({
						...prevState,
						destination_provinces: data,
					}));
				}
			} catch (error) {
				console.error("Error fetching provinces:", error);
			}
		};

		const fetchDepartureTowns = async () => {
			try {
				if (departure_province) {
					// Fetch data for Select 2 based on the selected value of Select 1
					const data = await TownService.by_province(departure_province);
					console.log(data);
					let filtered_towns = departure_province.toString() == '2' ? data.filter((town) => permittedTowns.includes(town.name)) : data
					setCreateOrderState((prevState) => ({
						...prevState,
						departure_towns: filtered_towns,
					}));
				}
			} catch (error) {
				console.error("Error fetching options for Select 2:", error);
			}
		};

		const fetchDestinationTowns = async () => {
			try {
				if (destination_province) {
					// Fetch data for Select 2 based on the selected value of Select 1
					const data = await TownService.by_province(destination_province);
					let filtered_towns = destination_province.toString() == '2' ? data.filter((town) => permittedTowns.includes(town.name)) : data

					console.log("Vutivi: " + JSON.stringify(filtered_towns));
					setCreateOrderState((prevState) => ({
						...prevState,
						destination_towns: filtered_towns,
					}));
				}
			} catch (error) {
				console.error("Error fetching options for Select 2:", error);
			}
		};

		fetchProvinces();
		fetchDepartureTowns();
		fetchParcels();
		fetchDestinationProvinces();
		fetchDestinationTowns();
	}, [departure_province, destination_province]);

	const handleSubmit = (e) => {
		e.preventDefault();
		createOrder();
	};

	const createOrder = () => {
		const formData = {
			departure_province,
			destination_province,
			departure_town,
			destination_town,
			parcel_type,
			provinces,
			departure_towns,
			destination_towns,
			parcels,
		};
		//Scroll tot top
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
		navigate("/cart", { state: formData });
	};

	return (
		<div className="w-full flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200">
			<div className="text-center py-6 px-6 lg:px-8 lg:py-10">
				<div className="text-purple-800 text-3xl md:text-=2xl font-semibold text-center ">Long Distance Parcel Delivery, Finally Done Right!</div>
			</div>
			<div className="grid grid-cols-1 gap-4 lg:grid-cols-2 pb-10 ">
				<div className="flex justify-center flex-col ">
					{/* <div className="text-center py-6 px-6 lg:px-8 lg:py-8">
						<div className="text-purple-800 text-3xl md:text-4xl font-semibold "> Sending same-day parcels, across provinces.</div>
						<div className="text-purple-800 text-3xl md:text-4xl font-semibold "> Finally, worth it!</div>
					</div> */}
					<div className="flex items-center justify-center">
						<img className="h-96" src={banner} alt="Parcelin" />
					</div>
				</div>

				<div className="flex justify-center">
					<form className="w-[80%] py-4 bg-gradient-to-b from-purple-400 to-indigo-400 rounded-3xl flex flex-col justify-center items-center gap-4" onSubmit={handleSubmit}>
						<div className="flex justify-evenly w-full ">
							<div class="relative inline-block text-left w-[45%]">
								<div className="text-white text-2xl font-bold text-left">Collect From</div>
							</div>

							<div class="relative inline-block text-left w-[45%]">
								<div className="text-white text-2xl font-bold text-right">Deliver To</div>
							</div>
						</div>
						<div className="flex justify-evenly w-full ">
							<div class="relative inline-block text-left w-[45%]">
								<select
									required
									onChange={handleSelectChange}
									value={departure_province}
									id="departure_province"
									class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
								>
									<option value="" disabled>
										Select Province
									</option>
									{provinces &&
										provinces.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</select>
							</div>

							<div class="relative inline-block text-left w-[45%]">
								<select
									required
									onChange={handleSelectChange}
									value={destination_province}
									id="destination_province"
									class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
								>
									<option value="" disabled>
										Select Province
									</option>
									{destination_provinces &&
										destination_provinces.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</select>
							</div>
						</div>
						<div className="flex justify-evenly w-full ">
							<div class="relative inline-block text-left w-[45%]">
								<select
									required
									onChange={handleSelectChange}
									value={departure_town}
									id="departure_town"
									class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
								>
									<option value="" disabled>
										Select Town
									</option>
									{departure_towns &&
										departure_towns.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</select>
							</div>

							<div class="relative inline-block text-left w-[45%]">
								<select
									required
									onChange={handleSelectChange}
									value={destination_town}
									id="destination_town"
									class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
								>
									<option value="" disabled>
										Select Town
									</option>
									{destination_towns &&
										destination_towns.map((option) => (
											<option key={option.id} value={option.id}>
												{option.name}
											</option>
										))}
								</select>
							</div>
						</div>
						<div className="flex justify-evenly w-full ">
							<div class="relative inline-block text-center">
								<div className="text-white text-2xl font-bold text-left">Package Details</div>
							</div>
						</div>
						<div className="flex justify-evenly w-full ">
							{parcels &&
								parcels.map((parcel, index) => (
									<div className="w-[45%] bg-white rounded-2xl border-2 border-white">
										<div className="flex flex-row items-center gap-4 p-2 ">
											<div className="text-black text-base font-bold ">{parcel.package_type}</div>
											<input required type="radio" class="form-radio text-blue-500 h-4 w-4" name="parcel_type" onChange={handleRadioButtonChange} value={parcel.id} id="parcel_type" />
										</div>
										<div className="text-black text-sm px-2 font-light">From R{start_prices[index]}</div>
										<div className="flex p-2 ">
											<div className="w-fit px-2 font-light text-black text-xs  rounded-3xl border border-zinc-100">
												{parcel.max_height} x {parcel.max_width} {parcel.max_weight}
											</div>
										</div>
									</div>
								))}
						</div>
						<div className="flex justify-evenly w-full ">
							<div class="relative inline-block text-center">
								<FormAction handleSubmit={handleSubmit} text="Checkout" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4" />
							</div>
						</div>
					</form>
				</div>
			</div>

			<div className="text-center py-6 px-6 lg:px-8 lg:py-8 bg-white">
				<div className="text-purple-800 text-3xl md:text-4xl font-semibold ">Why Parcelin?</div>
			</div>

			<div className="text-center py-6 px-6 lg:px-8 lg:py-8 bg-white">
				<div className="grid grid-cols-1 gap-2 lg:gap-10 lg:grid-cols-3">
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlBasketLoaded size={30} /> <p className="text-lg font-light text-start">Instant Online Quote</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlHome size={30} /> <p className="text-lg font-light text-start">Door(Gauteng) to Town(Limpopo) deliveries</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlPeople size={30} /> <p className="text-lg font-light text-start">Solutions for Individuals & Businesses</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlNotebook size={30} /> <p className="text-lg font-light text-start">Compare SA's best Couriers(Save Up to 60%)</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlWallet size={30} /> <p className="text-lg font-light text-start">Save 10% just by registering</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlUmbrella size={30} /> <p className="text-lg font-light text-start">Parcel Protection Guaranatee</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlDiamond size={30} /> <p className="text-lg font-light text-start">Transparent(No hidden Charges)</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlHeart size={30} /> <p className="text-lg font-light text-start">Same Day, Overnight or Next Friday Deliveries</p>
						</div>
					</div>
					<div className="flex">
						<div className="flex gap-4 justify-center items-center">
							<SlPhone size={30} /> <p className="text-lg font-light text-start">Online Support</p>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full bg-gradient-to-br from-cyan-300 to-emerald-600 py-6 px-6 lg:px-20 lg:py-20">
				<div className="w-full  bg-white rounded-3xl py-6 px-6">
					<div className="text-purple-800 text-3xl md:text-4xl font-semibold pb-8 text-center ">Send in 3 Steps</div>

					<div className="grid grid-cols-1 gap-2 lg:gap-10 lg:grid-cols-3">
						<div className="flex flex-col gap-4 justify-center items-center">
							<div className="flex p-5 bg-violet-700 rounded-full justify-center items-center">
								<FiBox size={30} color="white" />
							</div>

							<p className="text-lg font-light">1. PACK, WEIGH & MEASURE</p>
							<p className="text-center font-light">Seal your parcel carefully to prevent any issues in transit. Measure and weigh.</p>
						</div>

						<div className="flex flex-col gap-4 justify-center items-center">
							<div className="flex p-5 bg-violet-700 rounded-full justify-center items-center">
								<CiMonitor size={30} color="white" />
							</div>
							<p className="text-lg font-light">2. COMPARE & ORDER </p>
							<p className="text-center font-light">Complete online scheduling and management of pickups and shipments.</p>
						</div>

						<div className="flex flex-col gap-4 justify-center items-center">
							<div className="flex p-5 bg-violet-700 rounded-full justify-center items-center">
								<FiUser size={30} color="white" />
							</div>
							<p className="text-lg font-light">3. WAIT FOR COLLECTION</p>
							<p className="text-center font-light">No need to drop off your parcel - the courier comes to you. Track your parcel until it reaches its destination.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="text-center py-6 px-6 lg:px-8 lg:py-8 bg-white">
				<div className="text-center text-purple-800 text-3xl md:text-4xl font-semibold pb-8">Customer Reviews</div>

				<div className="grid grid-cols-1 gap-2 lg:gap-10 lg:grid-cols-4">
					<div className=" bg-white rounded-2xl shadow border border-zinc-300">
						<div className="flex gap-4 px-5 py-3 justify-start items-center">
							<div className="flex p-2 bg-zinc-300 rounded-full justify-center items-center">
								<SlUser size={20} />
							</div>
							<p className="text-lg font-light text-start">Ntsakisi Manganyi</p>
						</div>
						<div className="flex gap-4 px-5 py-3  text-left font-extralight">
							Finally here, been waiting for this for a long time since I have been sending my parcels through Liftsearch. Tracking makes it more worthwhile.
						</div>
						<div className="flex gap-1 px-5 py-3  justify-end text-right">
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
						</div>
					</div>
					<div className=" bg-white rounded-2xl shadow border border-zinc-300">
						<div className="flex gap-4 px-5 py-3 justify-start items-center">
							<div className="flex p-2 bg-zinc-300 rounded-full justify-center items-center">
								<SlUser size={20} />
							</div>
							<p className="text-lg font-light text-start">Desmond Makhubele</p>
						</div>
						<div className="flex gap-4 px-5 py-3  text-left font-extralight">Really cool innovations, I use Liftsearch and Parcelin for all my logistics.</div>
						<div className="flex gap-1 px-5 py-3  justify-end text-right">
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
						</div>
					</div>
					<div className=" bg-white rounded-2xl shadow border border-zinc-300">
						<div className="flex gap-4 px-5 py-3 justify-start items-center">
							<div className="flex p-2 bg-zinc-300 rounded-full justify-center items-center">
								<SlUser size={20} />
							</div>
							<p className="text-lg font-light text-start">Nissan Maluleke</p>
						</div>
						<div className="flex gap-4 px-5 py-3  text-left font-extralight">I like the fact that its straight to the point and hassle free</div>
						<div className="flex gap-1 px-5 py-3  justify-end text-right">
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
						</div>
					</div>
					<div className=" bg-white rounded-2xl shadow border border-zinc-300">
						<div className="flex gap-4 px-5 py-3 justify-start items-center">
							<div className="flex p-2 bg-zinc-300 rounded-full justify-center items-center">
								<SlUser size={20} />
							</div>
							<p className="text-lg font-light text-start">Shongi Mukhari</p>
						</div>
						<div className="flex gap-4 px-5 py-3  text-left font-extralight">Helps with delivering Archaar and Bones to my clients in Limpopo. Very worth it.</div>
						<div className="flex gap-1 px-5 py-3  justify-end text-right">
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
							<BsStarFill size={15} color="gold" />
						</div>
					</div>
				</div>
			</div>
			
			<div className="w-full flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200">
				<div className="text-center py-6 px-6 lg:px-8 lg:py-10">
					<div className="text-purple-800 text-3xl md:text-=2xl font-semibold text-center pb-8">Service Level Agreements</div>
					<div className="grid gap-2 lg:gap-10 lg:grid-cols-1">
						<Accordion className="justify-center">
							<AccordionTab
								className="w-full bg-white rounded-2xl shadow p-2 justify-center"
								header={
									<div className="flex flex-col lg:flex-row  justify-center">
										<div className="w-full p-2">
											<div className="grid grid-cols-1 justify-center">
												<p className="text-lg font-light text-left">Parcelin 'Same Day' Delivery Option SLA</p>
											</div>
										</div>
									</div>
								}
							>
								<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
									<div className="flex  flex-col gap-4">
										<ul className="list-disc">
											<li className="text-left">Pay order before 9am of Delivery Date</li>
											<li className="text-left">Will be collected during office hours, within 3 hours after booking is paid</li>
											<li className="text-left">Will reach destination by 17pm same day</li>
											<li className="text-left">Must be collected in town for Limpopo and will be dropped at door for Gauteng, within 60km radius of Pretoria Central</li>
										</ul>
									</div>
								</div>
							</AccordionTab>
							<AccordionTab
								className="w-full bg-white rounded-2xl shadow p-2 justify-center"
								header={
									<div className="flex flex-col lg:flex-row  justify-center">
										<div className="w-full p-2">
											<div className="grid grid-cols-1 justify-center">
												<p className="text-lg font-light text-left">Parcelin 'Overnight' Delivery Option SLA</p>
											</div>
										</div>
									</div>
								}
							>
								<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
									<div className="flex  flex-col gap-4">
										<ul className="list-disc">
											<li className="text-left">Pay order before 2pm of Pickup Date</li>
											<li className="text-left">Will be collected during office hours, within 1 hour after booking is paid</li>
											<li className="text-left">Will reach destination by 8am the next day</li>
											<li className="text-left">Must be collected in town for Limpopo and will be dropped at door for Gauteng, within 60km radius of Pretoria Central</li>
										</ul>
									</div>
								</div>
							</AccordionTab>
							<AccordionTab
								className="w-full bg-white rounded-2xl shadow p-2 justify-center"
								header={
									<div className="flex flex-col lg:flex-row  justify-center">
										<div className="w-full p-2">
											<div className="grid grid-cols-1 justify-center">
												<p className="text-lg font-light text-left">Parcelin 'Next Friday' Delivery Option SLA</p>
											</div>
										</div>
									</div>
								}
							>
								<div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
									<div className="flex  flex-col gap-4">
										<ul className="list-disc">
											<li className="text-left">Pay order before 4pm, Thursday</li>
											<li className="text-left">Will be collected during office hours, within 3 hours after booking is paid</li>
											<li className="text-left">Will reach destination by Next Saturday 1pm</li>
											<li className="text-left">Must be collected in town for Limpopo and will be dropped at door for Gauteng, within 60km radius of Pretoria Central</li>
										</ul>
									</div>
								</div>
							</AccordionTab>
						</Accordion>
					</div>
				</div>
			</div>
		</div>
	);
};
