import React, { useEffect, useState } from "react";
import ParcelOrderService from "../../services/parcel-order.service";
import { EmptyState } from "../../components/EmptyState";
import OrderCard from "../../components/OrderCard";

export const ParcelsPage = () => {

	const [OrdersState, setOrderState] = useState({
		orders: [],
	});

	const { orders } = OrdersState;

	useEffect(() => {
		const fetchOrders = async () => {
			try {
				const data = await ParcelOrderService.index();
				setOrderState((prevState) => ({
					...prevState,
					orders: data,
				}));
			} catch (error) {
				console.error("Error fetching orders:", error);
			}
		};

		fetchOrders()
	}, [])

	return (
		<div className="w-full min-h- flex flex-col relative bg-gradient-to-br from-cyan-100 to-fuchsia-200 ">
			<div className="p-5 lg:px-20 lg:py-20 flex flex-col gap-4">
				<div className=" text-black text-lg md:text-3xl font-extrabold ">Pending Deliveries</div>
				<div className="flex flex-col gap-2">
					<div className="text-black text-lg font-light ">{Date.UTC}</div>
				</div>
				{ (orders && (orders?.upcoming_parcels?.length > 0)) ? orders?.upcoming_parcels?.map((order) =>
					<OrderCard
						order={order}
					/>
				) :
				<EmptyState />}

				<div className=" text-black text-lg md:text-3xl font-extrabold ">Past Deliveries</div>

				<div className="flex flex-col gap-2">
					{/* <div className="text-black text-lg font-light ">29 Dec 2022</div> */}
				</div>

				
				{ (orders && (orders?.past_parcels?.length > 0)) ? orders?.past_parcels?.map((order) =>
						<OrderCard
							order={order}
						/>
				) : <EmptyState />}
				</div>
		</div>
	);
};
