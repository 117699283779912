import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthService from "../services/auth.service";

const PrivateRoute = () => {
    const authorized = AuthService.isLogin(); // determine if authorized, from context or however you're doing it

    return authorized ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;