import { useState, useEffect } from "react";
import { passwordResetFields } from "../constants/formFields";
import FormAction from "./FormAction";
import Input from "./Input";
import AuthService from "../services/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const fields = passwordResetFields;
let fieldsState = { hasError: false, errorMessage: '', isLoading: false, successReset: false };
fields.forEach((field) => (fieldsState[field.id] = ""));

export default function ResetRequest() {
    const location = useLocation();
    const navigate = useNavigate();
    
	const [resetState, setResetState] = useState(fieldsState);

	const handleChange = (e) => {
		setResetState({ ...resetState, [e.target.id]: e.target.value });
	};

    let { hasError, errorMessage, isLoading, successReset } = resetState

    useEffect(() => {

        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            if (jwtDecode(currentUser["token"]).exp > Date.now() / 1000) {
                navigate('/parcels', {})
            }
        }
    }, [])

	const handleSubmit = (e) => {
        e.preventDefault();
        setResetState({ ...resetState,
            hasError: false,
        });
		ResetPassword();
	};

	//Handle Login API Integration here
	const ResetPassword = () => {
        setIsLoading(true);

		let passwordResetFields = {
			email: resetState["email"]
		};

		AuthService.ResetRequest(passwordResetFields.email).then(
			(data) => {
                setIsLoading(false);
                setResetState({ ...resetState,
                    successReset: true
                });
			},
			(error) => {
				console.log(error?.response?.data?.message);
			});
	};

    const setIsLoading = (value) => {
        setResetState({ ...resetState,
            isLoading: value
        });
    }

	return (
		<form className="py-10 px-10  flex flex-col gap-4 w-full md:w-[60%] lg:w-[35%] bg-white  rounded-lg" onSubmit={handleSubmit}>
                {successReset && <div class="p-4 mb-4 text-sm text-purple-800 rounded-lg bg-purple-50 dark:bg-gray-800 dark:text-purple-400" role="alert">
                    Check your inbox for the password reset link, also check your SPAM folder.
                </div>}
                {
                    fields.map(field=>
                            <Input
                                key={field.id}
                                handleChange={handleChange}
                                value={resetState[field.id]}
                                labelText={field.labelText}
                                labelFor={field.labelFor}
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                isRequired={field.isRequired}
                                placeholder={field.placeholder}
                        />
                    
                    )
                }
			<FormAction handleSubmit={handleSubmit} loading={isLoading} text="Reset Password" />
		</form>
	);
}
