export const EmptyState = () => {
	return (
		<div class="flex flex-wrap items-center rounded-md">
            <div class="text-center border-dashed border-2 border-slate-200 dark:border-slate-100 rounded-md w-full p-20">
                <i class='bx bxs-contact bx-lg mb-5 dark:text-white'></i>
                <p class="text-xl mb-2 uppercase font-bold dark:text-gray">Nothing to show here yet.</p>
                <spam class="text-m text-slate-400 block mb-10 dark:text-slate-500">Get started with sending your Parcels.</spam>
                <a href="/" class="bg-purple-600 rounded-full px-5 py-3 text-white hover:bg-purple-500 w-52">Start Parcelin</a>
            </div>
        </div>
	);
};
