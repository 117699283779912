import { useState  } from "react";
import { Stepper, Step } from 'react-form-stepper';
import { BsArrowRight } from "react-icons/bs";
import { LiaPlusSolid, LiaMinusSolid } from "react-icons/lia";
import FormAction from "../../components/FormAction";
import Input from "../../components/Input";
import { useNavigate, useLocation } from "react-router-dom";
import capitec from "../../assets/payments/capitec.png";
import fnb from "../../assets/payments/fnb.png";
import nedbank from "../../assets/payments/nedbank.png";
import payfast from "../../assets/payments/payfast.png";
import { LiaCheckCircle } from "react-icons/lia";
import { BsCheck2Circle } from "react-icons/bs";
import AuthService from "../../services/auth.service";
import ParcelOrderService from "../../services/parcel-order.service"

export const CartPage = () => {
	const location = useLocation();

	const [createOrderState, setCreateOrderState] = useState({
		departure_province: location.state.departure_province,
		destination_province: location.state.destination_province,
		departure_town: location.state.departure_town,
		pickup_location: "",
		destination_town: location.state.destination_town,
		collection_date: "",
		parcel_type: location.state.parcel_type,
		payment_type: "",
		provinces: location.state.provinces,
		destination_provinces: [],
		delivery_type: location.state.parcels?.filter((parcel) => parcel.id.toString() === location.state.parcel_type.toString())[0]?.parcel_prices[0]?.price?.id,
		quantity: 1,
		redirect_to: "/cart",
		departure_towns: location.state.departure_towns,
		destination_towns: location.state.destination_towns,
		parcels: location.state.parcels,
		parcel_prices: location.state.parcels?.filter((parcel) => parcel.id.toString() === location.state.parcel_type.toString())[0].parcel_prices,
		order: {},
		showAlert: false
	});

	const { departure_province, destination_province, departure_town, destination_town, parcel_type, provinces, destination_provinces, departure_towns, destination_towns, delivery_type, payment_type, parcels, quantity, pickup_location, parcel_prices, collection_date, order, showAlert } = createOrderState;

	const navigate = useNavigate();


	const handleChange = (e) => {
		setCreateOrderState({ ...createOrderState, [e.target.id]: e.target.value });
	};

	const handleSelectChange = (event) => {
		const { id, value } = event.target;

		setCreateOrderState((prevState) => ({
			...prevState,
			[id]: value,
		}));

		// You can perform additional actions when the selection changes
	};

	const handleRadioButtonChange = (event) => {
		const { name, value } = event.target;

		setCreateOrderState((prevState) => ({
			...prevState,
			[name]: value,
			[showAlert]: value === 'mobile' ? true : false
		}));
	};
	
	const handleSubmit = (event) => {
		event.preventDefault();

		if(step === 1 && AuthService.isLogin()){
			ParcelOrderService.create(AuthService.getCurrentUser()?.user?.id, parcel_type, delivery_type, pickup_location, quantity, collection_date, departure_province, destination_province, departure_town, destination_town).then(
				(data) => {
					setCreateOrderState((prevState) => ({
						...prevState,
						order: data,
					}));
			
					setGoSteps(step);
				},
				(error) => {
					console.log(error);
				}
			);
		}else if(step === 2 && AuthService.isLogin()){
			if(payment_type === 'payfast'){
				window.location.assign(`https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=11799876&item_name=Parcelin%20Order+${order?.reference_number}&item_description=Parcelin%20Order+R${order?.price?.value*quantity}+deposit+per+seat&amount=${order?.price?.value*quantity}&return_url=https://parcelin.co.za/parcels&cancel_url=https://parcelin.co.za/cancel_payment?parcel_order_id=${order?.id}`);
			}else{
				setGoSteps(step);
			} 
		} else if(step == 3 && AuthService.isLogin()){
			navigate("/parcels");
		}else{		
			navigate("/login", { state: createOrderState });
		}
	};

	const addQuantity = (event) => {
		event.preventDefault();
		setCreateOrderState((prevState) => ({
			...prevState,
			quantity: quantity + 1,
		}));
	}

	const subtractQuantity = (event) => {
		if((quantity - 1) == 0){
			return 
		}

		event.preventDefault();
		setCreateOrderState((prevState) => ({
			...prevState,
			quantity: quantity - 1,
		}));
	}

	const [goSteps, setGoSteps] = useState(0);

	let step = 0;
	
	return (
		<div className="">
			<Stepper activeStep={goSteps}>
				<Step onClick={() => setGoSteps(0)} label="Details" />
				<Step onClick={() => setGoSteps(1)} label="Payment" />
				<Step onClick={() => setGoSteps(2)} label="Finish" />
			</Stepper>
			{goSteps === 0 && (
				<div className="w-full min-h- flex flex-col ">
						<div className="p-5 lg:py-20 flex flex-col gap-4 items-center">
							<form className="w-96  bg-white rounded-xl border border-purple-200 gap-4 p-4 justify-center items-center" onSubmit={handleSubmit}>
								<div className="flex justify-evenly w-full p-2">
									<div class="relative inline-block text-left w-[45%] ">
										<div className=" text-base font-bold text-left">{provinces.filter((province) => province.id.toString() === location.state.departure_province)[0]?.name}</div>
										<div className=" text-sm font-bold text-left">{departure_towns.filter((town) => town.id.toString() === location.state.departure_town)[0]?.name}</div>
									</div>
			
									<div className="flex justify-center  items-center">
										<BsArrowRight size={20} />
									</div>
			
									<div class="relative inline-block text-left w-[45%] ">
										<div className=" text-base font-bold text-right">{provinces.filter((province) => province.id.toString() === location.state.destination_province)[0]?.name}</div>
										<div className=" text-sm font-bold text-right">{destination_towns.filter((town) => town.id.toString() === location.state.destination_town)[0]?.name}</div>
									</div>
								</div>
			
								<div className="flex flex-col gap-2 p-2">
									<label class="block text-gray-700 text-sm font-bold mb-2" for="collection-date">
										Collection Date
									</label>
									<Input
											required
											key="collection_date"
											handleChange={handleChange}
											value={createOrderState['collection_date']}
											labelText="Collection Date"
											labelFor="collection-date"
											id="collection_date"
											name="collection_date"
											type="date"
											isRequired={true}
											placeholder="mm/dd/yyyy"
									/>
								</div>

								<div className="flex flex-col gap-2 p-2">
									<label class="block text-gray-700 text-sm font-bold mb-2" for="collection-address">
										Collection Address
									</label>
									<Input
										required
										key="pickup_location"
										handleChange={handleChange}
										value={createOrderState['pickup_location']}
										labelText="Collection Address"
										labelFor="collection-address"
										id="pickup_location"
										name="pickup_location"
										type="text"
										isRequired={true}
									/>
								</div>

								<div className="flex flex-col gap-2 p-2">
									<label class="block text-gray-700 text-sm font-bold mb-2" for="price">
										Price
									</label>
									<select
										onChange={handleSelectChange}
										value={delivery_type}
										id="delivery_type"
										required
										class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-3 rounded-xl shadow leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm"
									>
										<option value="" disabled>
											Deliver When?
										</option>

										{(parcels && parcel_prices) && parcel_prices.map((price) => (
											<option key={price.id} value={price.price?.id}>
												{`${price.price?.price_type?.name} R${price.price?.value}`}
											</option>
										))}
									</select>
								</div>

								<div className="flex flex-col gap-2 p-2">
									<div className="flex justify-between items-center	">
										<p className="font-semibold">Quantity</p>
										<div className="flex flex-row gap-4 items-center">
											<LiaMinusSolid size={15} className="cursor-pointer" onClick={subtractQuantity} />
											<p className="font-semibold">{quantity}</p>
											<LiaPlusSolid size={15} onClick={addQuantity} className="cursor-pointer" />
										</div>
									</div>
									<div className="flex justify-between items-center">
										<p className="font-semibold">Total</p>
										<p className="font-semibold">R{parcel_prices.filter((parcel_price => parcel_price?.price?.id.toString() === delivery_type.toString()))[0]?.price?.value * quantity}</p>
									</div>
								</div>

								<div class="flex flex-col justify-center items-center pt-5">
									<FormAction onClick={handleSubmit} text="Proceed" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4" icon={<BsArrowRight size={20} />} />
								</div>
							</form>
						</div>
						<div className="hidden">{ step = 1}</div>
					</div>
			)}
			{goSteps === 1 && (
				<div className="w-full min-h- flex flex-col ">
					<div className="p-5 lg:py-20 flex flex-col gap-4 items-center">
						<form className="w-96  bg-white rounded-xl border border-purple-200 gap-4 p-4" onSubmit={handleSubmit}>
							<div className="flex justify-evenly w-full p-2">
								<div className="text-black text-base font-bold text-left">How would you like to pay?</div>
							</div>

							<div className=" bg-stone-100 rounded-2xl flex flex-col gap-4 p-4">
								<div className="flex justify-between">
									<p className="text-black text-base font-bold text-left">Amount</p>
									<p className="text-black text-base font-bold text-left">R{order?.price?.value * quantity}</p>
								</div>
								<div className="flex justify-between">
									<p className="text-black text-base font-bold text-left">Reference</p>
									<p className="text-black text-base font-bold text-left">{order.reference_number}</p>
								</div>
							</div>
										
							{ payment_type === 'mobile' && <div  class="max-w-full p-8 bg-white rounded-lg shadow-lg w-96">
								<div class="flex items-center mb-6">
									<svg class="h-8 w-8 text-purple-500 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
									</svg>
									<h4 class="font-semibold ml-3 text-lg">Mobile Payment Instructions</h4>
								</div>
									<div>
										<input class="hidden" type="checkbox" id="task_2" checked />
										<label class="flex items-center h-10 px-2 rounded cursor-pointer hover:bg-gray-100" for="task_2">
											<span class="flex items-center justify-center w-5 h-5 text-transparent border-2 border-gray-300 bg:indigo-500  rounded-full">
												<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
												</svg>
											</span>
											<span class="ml-4 text-sm">E-wallet, Money Send, Payshap or Capitec Accepted </span>
										</label>	
									</div>
									<div>
										<input class="hidden" type="checkbox" id="task_2" checked />
										<label class="flex items-center h-10 px-2 rounded cursor-pointer hover:bg-gray-100" for="task_2">
											<span class="flex items-center justify-center w-5 h-5 text-transparent border-2 border-gray-300 rounded-full">
												<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
												</svg>
											</span>
											<span class="ml-4 text-sm">Mobile Number 069 9042 846</span>
										</label>	
									</div>
									<div>
										<input class="hidden" type="checkbox" id="task_2" checked />
										<label class="flex items-center h-10 px-2 rounded cursor-pointer hover:bg-gray-100" for="task_2">
											<span class="flex items-center justify-center w-5 h-5 text-transparent border-2 border-gray-300 rounded-full">
												<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
												</svg>
											</span>
											<span class="ml-4 text-sm">Reference Number {order.reference_number}</span>
										</label>	
									</div>
									<div>
										<input class="hidden" type="checkbox" id="task_2" checked />
										<label class="flex items-center h-20 px-2 rounded cursor-pointer hover:bg-gray-100" for="task_2">
											<span class="flex items-center justify-center w-5 h-5 text-transparent border-2 border-gray-300 rounded-full">
												<svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
												</svg>
											</span><br />
											<span class="ml-4 text-sm">Click continue after paying. Once payment is confirmed we shall contact you for collection arrangements.</span>
										</label>	
									</div>
							</div>}
								
							<div className="flex flex-col gap-2 p-2">
								<div className="text-black text-base font-bold text-left">Via Mobile Number</div>
								<div className="flex justify-between bg-white rounded-2xl border-2 border-stone-100 p-3">
									<img className="h-9" src={fnb} alt="" />
									<img className="h-9" src={nedbank} alt="" />
									<img className="h-9" src={capitec} alt="" />
									<input type="radio" name="payment_type" onChange={handleRadioButtonChange} value="mobile" id="payment_type" required />

									{/* <input type="radio" name="paymet" id="mobile" /> */}
								</div>
							</div>

							<div className="flex flex-col gap-2 p-2">
								<div className="text-black text-base font-bold text-left">EFT with Payfast</div>

								<div className="flex justify-between bg-white rounded-2xl border-2 border-stone-100 p-3">
									<img className="h-9" src={payfast} alt="" />
									<input type="radio" name="payment_type" onChange={handleRadioButtonChange} value="payfast" id="payment_type" required />

									{/* <input type="radio" name="paymet" id="payfast" /> */}
								</div>
							</div>

							<div class="flex flex-col justify-center items-center pt-5">
								<FormAction handleSubmit={handleSubmit} text="Proceed" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4" icon={<BsArrowRight size={20} />} />
							</div>
						</form>
					</div>
					<div className="hidden">{ step = 2}</div>
				</div>
			)}
			{goSteps === 2 && (
					<div className="w-full min-h- flex flex-col ">
						<div className="p-5 lg:py-20 flex flex-col gap-4 items-center">
							<form className="flex flex-col w-96 h-full bg-white rounded-xl border border-purple-200 gap-4 p-4 justify-center items-center" onSubmit={handleSubmit}>
								<div className="flex flex-col gap-2 p-2">
									<div className="text-black k text-base font-bold text-center">Order Confirmed</div>
								</div>
			
								<div className="flex flex-col gap-2 p-2">
									<LiaCheckCircle size={190} className="cursor-pointer" color="green" />
								</div>
			
								<div className="flex flex-col gap-2 p-2">
									<div className="text-black text-base font-light text-center">Your Order has been placed. Here is your Tracking Number</div>
								</div>
			
								<div className="flex flex-col gap-2 p-2">
									<div className="w-32 h-6 text-center text-zinc-500 text-xl font-medium ">{order?.reference_number}</div>
								</div>
			
								<div class="relative inline-block text-center pt-5">
									<FormAction handleSubmit={handleSubmit} text="Proceed" className="text-white bg-gradient-to-br from-blue-600 to-fuchsia-600 rounded-3xl  py-2 px-4" icon={<BsCheck2Circle size={20} />} />
								</div>
							</form>
						</div>
						<div className="hidden">{ step = 3}</div>
					</div>
			)}
		</div>
	);
};
