import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class ParcelService {
  index() {
    return axios.get(API_URL + "parcels", {}).then(response => {
        return response.data;
    });
  }

  excluding_province(id) {
    return axios.get(API_URL + "parcels", {}).then(response => {
        return response.data.filter((province) => province.id.toString() !== id.toString() );
    });
  }
}

export default new ParcelService();